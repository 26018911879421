"use client"

import { Stack } from "@/components/ui/layout/Stack"
import UnexpectedError from "./_components/UnexpectedError"
import RootStyleRegistry from "./_providers/emotion"
import RootThemeProvider from "./_providers/theme"
import { fallbackLocale } from "@/i18n"

import "@/app/globals.css"

interface GlobalErrorProps {
  error: Error & { digest?: string }
  reset: () => void
}

export default function GlobalError({ error, reset }: GlobalErrorProps) {
  const defaultLocale = fallbackLocale

  return (
    <html lang={defaultLocale}>
      <head>
        {/**
         * as of now, loading next/fonts is a known limitation at turbopack, we will use the global font for now
         * https://nextjs.org/docs/messages/no-page-custom-font#possible-ways-to-fix-it
         */}
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto&display=optional"
          rel="stylesheet"
        />
      </head>
      <body id="root">
        <RootStyleRegistry>
          <RootThemeProvider>
            <GlobalError error={error} reset={reset} />
          </RootThemeProvider>
        </RootStyleRegistry>
      </body>
    </html>
  )
}
